import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import "./auth.css";

export const Logout = ({setToken, setCallbackURL}) => {
    
    const [params, setParams] = useSearchParams();
    const navigate = useNavigate();
    
    Cookies.remove("token", { domain: '.groditech.com', expires: 7 });
    // Cookies.remove("token");
    setToken(null);

    useEffect(() => {
        if (params.get("backurl")) {
          setCallbackURL(params.get("backurl"));
        }
      }, [params]);

    useEffect(() => {
        // localStorage.removeItem("token");
        navigate("/login");
        return () => {};
    }, []);

    return null;

};
