import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { userAPI } from '../api';
import "./auth.css";

export const PassRecovery = ({setCallbackURL}) => {
  const [login, setLogin] = useState({
    email: ""
  });

  const [noAccount, setNoAccount] = useState(false);
  const [noExists, setNoExists] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [serverError, setServerError] = useState(false);

  const [params, setParams] = useSearchParams();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLogin({ ...login, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    setNoExists(false);
    setNoAccount(false);
    setServerError(false);
    if (login.email === "") {
      setNoAccount(true);
    } else {
      userAPI
        .post( "/user/passrecovery", login)
        .then((res) => {
          if (res.status === 200) {
            setShowForm(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setNoExists(true);
          }
          else
            setServerError(true);
        });
    }
  };

  useEffect(() => {
    if (params.get("backurl")) {
      setCallbackURL(params.get("backurl"));
    }
  }, [params]);

  return (
    <div className="container">
      <main className="login">
        <form className="login-form">

          <div className="row">
            <div className="col"><img className="logoGrodi" src="/logo2.png" /></div>
          </div>
          <h3 className="login-title mb-4">Recuperar contraseña</h3>

          <div className="mb-2 text-start">
            {showForm && (
              <>
              <div className="text-center mb-4">
                Introduce tu correo electrónico para recuperar tu contraseña. Recibirás una clave de un solo uso y podrás establecer una nueva.
              </div>
              <div className="form-group mb-0 check-valid text-dark">
                <div className="input-group input-group-lg">
                  <span className="input-group-text text-theme border-end-0"><i className="bi bi-envelope"></i></span>
                  <div className="form-floating">
                    <input
                      id="email"
                      className="form-control border-start-0"
                      autoFocus
                      placeholder="Email"
                      type="email"
                      autoComplete="off"
                      name="email"
                      value={login.email}
                      onChange={handleChange}
                      onKeyDown={(e) => {e.key == 'Enter' && handleSubmit(e)}}
                      required
                    />
                    <label htmlFor="email">Correo electrónico</label>
                  </div>
                </div>
              </div>
              </>
            )}
            {!showForm && 
              (<div className="text-center mb-4">
                  Revisa tu correo eléctronico, y recuerda <b>cambiar la contraseña</b> una vez entres en tu cuenta<br/>
              </div>)
            }
            {noAccount && 
              (<div className="text-center mt-3 fw-bold">
                  Introduce una cuenta de correo válida<br/>
              </div>)
            }
            {serverError &&
              (<div className="text-center mt-3 fw-bold text-danger">
                  Se ha producido un error<br/>
              </div>)
            }
            {noExists && 
              (<div className="text-center mt-3 fw-bold ">
                No existe ninguna cuenta con este correo,<br/>
                cree una nueva <Link as={Link} to="/register" className="login-link"><span>AQUÍ</span></Link>
              </div>)
            }
          </div>

          <div className="row">
            {showForm && 
              (<div className="col">
                <button className="btn btn-lg my-3 login-btn w-100" type="button" id="submitbtn" onClick={handleSubmit}>Enviar correo</button>
              </div>)
            }
            <div className="text-body">
              Volver a <Link as={Link} to="/" className="login-link"><span>LOGIN</span></Link>
            </div>
          </div>
        </form>
      </main>

    </div>
  );
};
