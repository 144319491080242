import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { userAPI } from '../api';
import "./auth.css";

export const ConfirmEmail = () => {

  const [ confirm, setConfirm ] = useState(0);
  const [ params, setParams ] = useSearchParams();

  useEffect(() => {
    userAPI.post('/user/confirmEmail', { token: params.get('token'), email: params.get('email') })
      .then(res => {
        if (res.data.result.affectedRows > 0) {
          setConfirm(1);
        } else {
          setConfirm(2);
        }
      })
      .catch(err => {
        console.log(err);
        setConfirm(2);
      });
  }, []);

  return (
    <div className="container">
      <main className="login">
        <div className="login-form">

        <div className="row">
            <div className="col"><img className="logoGrodi" src="/logo2.png" /></div>
        </div>
        <h3 className="login-title mb-4">Confirmación de e-mail</h3>

          <div className="row">
            <div className="col mb-4">
              {confirm == 0 && <div className="text-secondary fs-3">Validando...</div>}
              {confirm == 1 && <div className="text-success fs-3">Email confirmado correctamente</div>}
              {confirm == 2 && <div className="text-danger fs-3">Error al confirmar el email</div>}
            </div>
            {confirm != 0 &&
              <div className="text-body">
              Ir a página de <Link as={Link} to="/" className="login-link"><span>LOGIN</span></Link>
              </div>
            }
          </div>

        </div>
      </main>
    </div>
  );
};