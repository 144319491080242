import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { mediaURL, userAPI } from "../api";
import "./auth.css";

export const ChangePass = ({token, callbackURL, setCallbackURL}) => {
  
  const [params, setParams] = useSearchParams();
  const [serverError, setServerError] = useState(false);
  const [passMatch, setPassMatch] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const { user } = jwtDecode(token);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name === "password")
        setPassword(value);
    else
        setPassword2(value);
  };

  const handleChangePass = () => {
    if (password2 == password)
      setPassMatch(true);
    else
      setPassMatch(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setServerError(false);

    if (document.querySelector('#password2').value != password){
      window.alert('Las contraseñas no coinciden');
      return
    }else{
      if (password === "" || password2 === "")
        window.alert('Complete los campos obligatorios');
      else {
        setDisableBtn(true);
        userAPI
          .post("/user/changePass", {password: password}, {headers: {authorization: token}})
          .then((res) => {
            navigate('/');
          })
          .catch((err) => {          
            setServerError(true);
            setDisableBtn(false);
          });
      }
    }
  };

  useEffect(() => {
    handleChangePass();
  }, [password, password2])

  const viewPass = () => {
    var passInput = document.getElementById("password1");
    var passView = document.getElementById("viewpassword1");
    if (passInput.attributes.type.value === "password") {
      passInput.attributes.type.value = "text";
      passView.children.item(0).setAttribute("class", "bi bi-eye-slash clickable");
    }
    else {
      passInput.attributes.type.value = "password";
      passView.children.item(0).setAttribute("class", "bi bi-eye clickable");
    }
  };

  const viewRePass = () => {
    var passInput = document.getElementById("password2");
    var passView = document.getElementById("viewpassword2");
    if (passInput.attributes.type.value === "password") {
      passInput.attributes.type.value = "text";
      passView.children.item(0).setAttribute("class", "bi bi-eye-slash clickable");
    }
    else {
      passInput.attributes.type.value = "password";
      passView.children.item(0).setAttribute("class", "bi bi-eye clickable");
    }
  };
  
  useEffect(() => {
    if (params.get("backurl")) {
      setCallbackURL(params.get("backurl"));
    }
  }, [params]);

  return (
    <div className="container">
      <main className="login">
        <form className="login-form">
          <div className="row">
            <div className="col"><img className="logoGrodi" src="/logo2.png" /></div>
          </div>
          
          <h3 className="login-title mb-4">Cambio de contraseña</h3>

          <div className="row mb-3">
            <div className="col">
              <div className="profile-card">
                <img className="user-img" alt="profile" src={`${mediaURL}/images/profile/${user.img ? user.img : 'default.jpg'}`}/>
                <div className="user-data">
                  <div className="profile-name">{user.name} {user.lastname}</div>
                  <div className="profile-mail">{user.email}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col">
              <span className="fw-bold">Has usado una clave de recuperación.</span><br/>
              <span className="fw-bold text-danger">Debes cambiar la contraseña.</span>
            </div>
          </div>
          <div className="form-group mb-2 position-relative check-valid">
            <div className="input-group input-group-lg">
              <span className="input-group-text text-theme border-end-0"><i className="bi bi-key"></i></span>
              <div className="form-floating">
                <input
                  id="password1"
                  type="password"
                  placeholder="Introduce tu contraseña"
                  autoComplete="off"
                  name="password"
                  required
                  defaultValue={password}
                  onChange={handleChange}
                  className="form-control border-start-0 border-end-0"
                />
                <label htmlFor="password1">Contraseña</label>
              </div>
              <span className="input-group-text text-secondary  border-start-0" id="viewpassword1" onClick={viewPass}><i className="bi bi-eye clickable"></i></span>
            </div>
          </div>

          <div className="form-group mb-2 position-relative check-valid">
            <div className="input-group input-group-lg">
              {passMatch && (<span className="input-group-text text-theme border-end-0 text-success"><i className="bi bi-unlock"></i></span>)}
              {!passMatch && (<span className="input-group-text text-theme border-end-0 text-danger"><i className="bi bi-lock"></i></span>)}
              <div className="form-floating">
                <input
                  type="password"
                  placeholder="Re-introduzca contraseña"
                  name="password2"
                  required
                  defaultValue={password2}
                  className="form-control border-start-0 border-end-0"
                  onChange={handleChange}
                  id="password2" />
                <label htmlFor="password2">Confirme Contraseña</label>
              </div>
              <span className="input-group-text text-secondary  border-start-0" id="viewpassword2" onClick={viewRePass}><i className="bi bi-eye clickable"></i></span>
            </div>
          </div>

          <div className="row">
            {serverError && 
              (<div className="text-danger fw-bold my-2">
                Se ha producido un error<br/>
              </div>)
            }
          </div>

          <div className="row">
            <div className="col">
              <button className="btn btn-lg mt-3 login-btn w-100" type="button" id="submitbtn" onClick={handleSubmit} disabled={disableBtn}>Cambiar contraseña</button>
            </div>
          </div>
        </form>
      </main>
    </div>
  );
};