import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { mediaURL } from "../api";
import "./auth.css";

export const Profile = ({token, callbackURL, setCallbackURL}) => {
  
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const { user } = jwtDecode(token);

  const handleLogout = () => {
    navigate("/logout");
  };

  useEffect(() => {
    if (params.get("backurl")) {
      setCallbackURL(params.get("backurl"));
    }
  }, [params]);

  useEffect(() => {
    if (callbackURL) {
      window.location.replace(callbackURL);
    }
  }, [callbackURL]);

  return (
    <div className="container">
      <main className="login">
        <form className="login-form">
          <div className="row">
            <div className="col"><img className="logoGrodi" src="/logo2.png" /></div>
          </div>
          
          <h3 className="login-title mb-4">Sesión iniciada</h3>

          <div className="row mb-3">
            <div className="col">
              <div className="profile-card">
                <img className="user-img" alt="profile" src={`${mediaURL}/images/profile/${user.img ? user.img : 'default.jpg'}`}/>
                <div className="user-data">
                  <div className="profile-name">{user.name} {user.lastname}</div>
                  <div className="profile-mail">{user.email}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {!callbackURL && (
              <div className="col">
                La sesión se ha iniciado correctamente.<br/>
                Ya puedes acceder a nuestras apps.
              </div>
            )}
            {callbackURL && (
              <div className="col">
                Redirigiendo a la aplicación<br/>
                {callbackURL}
              </div>
            )}
          </div>

          <div className="row">
            <div className="col">
              <button className="btn btn-lg mt-3 login-btn w-100" type="button" id="submitbtn" onClick={handleLogout}>Cerrar sesión</button>
            </div>
          </div>
        </form>
      </main>
    </div>
  );
};
