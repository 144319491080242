import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Register } from "./pages/Register";
import { Login } from "./pages/Login";
import { PassRecovery } from "./pages/PassRecovery";
import { ConfirmEmail } from "./pages/confirmEmail";
import { Profile } from "./pages/Profile";
import { ChangePass } from "./pages/ChangePass";
import { Logout } from "./pages/Logout";
import {userAPI} from './api';
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

export const AppRoutes = () => {
  const [ token, setToken ]= useState(Cookies.get("token"));
  const [ callbackURL, setCallbackURL ] = useState(null);

  useEffect(() => {
    if (token) {
      // Check expiration date
      const expdate = jwtDecode(token).exp;
      if (expdate * 1000 < Date.now()) {
        Cookies.remove("token", { domain: '.groditech.com', expires: 7 });
        window.location.reload();
      }
      else if (((expdate * 1000) - Date.now()) < 518400000) {
        // Extend expiration date
        userAPI
          .get( "/user/tokenupdate", {headers: {Authorization: token}})
          .then((res) => {
            token = res.data.token;
            Cookies.set("token", token, { domain: '.groditech.com', expires: 7 });
          })
          .catch((error) => {
            Cookies.remove("token", { domain: '.groditech.com', expires: 7 });
            window.location.reload();
          });
      }
    }
  }, [token]);

  return (
    <Routes>
      {!token && 
        (<>
          <Route path="/" element={<Navigate to={"/login"} />} />
          <Route path="/login" element={<Login setToken={setToken} setCallbackURL={setCallbackURL} />} />
          <Route path="/register" element={<Register setCallbackURL={setCallbackURL} />} />
          <Route path="/passrecovery" element={<PassRecovery setCallbackURL={setCallbackURL} />} />
        </>)
      }
      {token &&
        (<>
          <Route path="/" element={<Profile token={token} callbackURL={callbackURL} setCallbackURL={setCallbackURL} />} />
          <Route path="/changepass" element={<ChangePass token={token} callbackURL={callbackURL} setCallbackURL={setCallbackURL} />} />
        </>)
      }
      <Route path="/logout" element={<Logout setToken={setToken} setCallbackURL={setCallbackURL} />} />
      <Route path="/confirmEmail" element={<ConfirmEmail />} />
      <Route path="*" element={<Navigate to={"/"} />} />
    </Routes>
  );
};
