import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { userAPI } from '../api';
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import "./auth.css";

export const Login = ({setToken, setCallbackURL}) => {

  // window.localStorage.removeItem("token");
  // setIsLogged(false);
  const [params, setParams] = useSearchParams();

  const navigate = useNavigate();
  // { window.localStorage.getItem('token') && (
  //   navigate('/', { replace: true })
  // )}
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });

  const [msgError, setMsgError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLogin({ ...login, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (login.email === "" || login.password === "") {
      setMsgError(true);
    } else {
      userAPI
        .post( "/user/userlogin", login)
        .then((res) => {
          //capturo el token que me manda el servidor
          const token = res.data.token;
          //Cookies.set("token", token, { expires: 30 });
          Cookies.set("token", token, { domain: '.groditech.com', expires: 7 });
          setToken(token);

          //comprobamos que tipo de usuario se está logeando
          const type = jwtDecode(token).user.type;
          const id = jwtDecode(token).user.id;
          //Redireccionamos segun tipo de user
          if (res.status == 202) {
            navigate(`/changepass`, { replace: true });
          }
          else {
            navigate(`/`, { replace: true })
          }
        })
        .catch((error) => {
          setMsgError(true);
        });
    }
  };

  const viewPass = () => {
    var passInput = document.getElementById("password");
    var passView = document.getElementById("viewpassword");
    if (passInput.attributes.type.value === "password") {
      passInput.attributes.type.value = "text";
      passView.children.item(0).setAttribute("class", "bi bi-eye-slash");
    }
    else {
      passInput.attributes.type.value = "password";
      passView.children.item(0).setAttribute("class", "bi bi-eye");
    }
  };

  useEffect(() => {
    if (params.get("backurl")) {
      setCallbackURL(params.get("backurl"));
    }
  }, [params]);

  return (
    <div className="container">
      <main className="login">
        <form className="login-form">
          <div className="row">
            <div className="col"><img className="logoGrodi" src="/logo2.png" /></div>
          </div>
          
          <h3 className="login-title mb-4">Inicio de sesión</h3>

          <div className="form-group mb-2 check-valid text-dark">
            <div className="input-group input-group-lg">
              <span className="input-group-text text-theme border-end-0"><i className="bi bi-envelope"></i></span>
              <div className="form-floating">
                <input
                  id="email"
                  className="form-control border-start-0"
                  autoFocus
                  placeholder="Email"
                  type="email"
                  autoComplete="off"
                  name="email"
                  value={login.email}
                  onChange={handleChange}
                  onKeyUp={(e)=>{
                    if (e.key == "Enter")
                      handleSubmit(e);
                  }}
                  required
                />
                <label htmlFor="email">Correo electrónico</label>
              </div>
            </div>
          </div>

          <div className="form-group mb-2 check-valid text-dark">
            <div className="input-group input-group-lg">
              <span className="input-group-text text-theme border-end-0"><i className="bi bi-key"></i></span>
              <div className="form-floating">
                <input
                  id="password"
                  className="form-control border-start-0 border-end-0"
                  placeholder="Contraseña"
                  type="password"
                  autoComplete="off"
                  name="password"
                  value={login.password}
                  onChange={handleChange}
                  onKeyUp={(e)=>{
                    if (e.key == "Enter")
                      handleSubmit(e);
                  }}
                  required
                />
                <label htmlFor="password">Contraseña</label>
              </div>
              <span className="input-group-text text-secondary  border-start-0 clickable" id="viewpassword" onClick={ viewPass }><i className="bi bi-eye"></i></span>
            </div>
          </div>

          {msgError && (<p className="login-alert">** Usuario y/o contraseña INCORRECTOS</p>)}
            
          <div className="row">
            <div className="col">
              <button className="btn btn-lg my-3 login-btn w-100" type="button" id="submitbtn" onClick={handleSubmit}>Inicio de Sesión</button>
            </div>
            
            <div className="text-body">
              ¿No tienes cuenta? Regístrate <Link as={Link} to="/register" className="login-link"><span>AQUÍ</span></Link>
              <hr/>
              Si has olvidado tu contraseña, <Link as={Link} to="/passrecovery" className="login-link"><span>RECUPÉRALA</span></Link>
            </div>
          </div>
        </form>
      </main>
    </div>
  );
};
