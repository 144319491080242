import React, { useEffect, useState } from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { userAPI } from '../api';
import "./auth.css";

export const Register = ({setCallbackURL}) => {
  
  //estado para mostrar mensaje rellenar campos
  const [regMessage, setRegMessage] = useState(false);
  const [emailMessage, setEmailMessage] = useState(false);
  //estado para setear las fotos
  const [photo, setPhoto] = useState("");
  const [params, setParams] = useSearchParams();
  const [register, setRegister] = useState({
    name: "",
    lastname: "",
    email: "",
    password: "",
    password2: "",
    phone: "",
    profile: "0",
    privacy: false,
    ageVerification: false
  });

  const [exists, setExists] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [passMatch, setPassMatch] = useState(false);
  const [showCheckMail, setShowCheckMail] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (params.get('regEmail') != null) {
      setRegister({ ...register, email: params.get('regEmail') });
    }
    if (params.get("backurl")) {
      setCallbackURL(params.get("backurl"));
    }
  }, [params]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name == 'privacy' || name == 'ageVerification')
      setRegister({ ...register, [name]: e.target.checked })
    else
      setRegister({ ...register, [name]: value });
  };

  const handleChangePass = () => {
    if (register.password2 == register.password)
      setPassMatch(true);
    else
      setPassMatch(false);
  };

  useEffect(()=>{handleChangePass()}, [register])

  const handleSubmit = (e) => {
    e.preventDefault();

    setExists(false);
    setServerError(false);
    setRegMessage(false);
    setShowCheckMail(false);

    // si vienen vacíos los campos
    if (register.password2 != register.password){
      window.alert('Las contraseñas no coinciden');
      return
    }else{
      if (
        register.name === "" ||
        register.lastname === "" ||
        register.email === "" ||
        register.password === "" ||
        register.phone === ""
      ) {
        setRegMessage(true);
        window.alert('Complete los campos obligatorios');
      }
      else if (!register.privacy || !register.ageVerification) {
        window.alert('Debes aceptar la política de privacidad y confirmar que eres mayor de edad')
      }
      else {
        setDisableBtn(true);
        userAPI
          .post("/user/createUser", {register: JSON.stringify(register)})
          .then((res) => {
            setShowCheckMail(true);
            //setUserChange(true);
          })
          .catch((err) => {          
            if (err.code == 'ERR_BAD_REQUEST' && err.response.data.error.errno === 1062) {
              setExists(true);
            } else {
              setServerError(true);
            }
            setDisableBtn(false);
          });
      }
    }
  };

  const handleFile = (e) => {
    setPhoto(e.target.files[0]);
  };

  const viewPass = () => {
    var passInput = document.getElementById("password1");
    var passView = document.getElementById("viewpassword1");
    if (passInput.attributes.type.value === "password") {
      passInput.attributes.type.value = "text";
      passView.children.item(0).setAttribute("class", "bi bi-eye-slash clickable");
    }
    else {
      passInput.attributes.type.value = "password";
      passView.children.item(0).setAttribute("class", "bi bi-eye clickable");
    }
  };

  const viewRePass = () => {
    var passInput = document.getElementById("password2");
    var passView = document.getElementById("viewpassword2");
    if (passInput.attributes.type.value === "password") {
      passInput.attributes.type.value = "text";
      passView.children.item(0).setAttribute("class", "bi bi-eye-slash clickable");
    }
    else {
      passInput.attributes.type.value = "password";
      passView.children.item(0).setAttribute("class", "bi bi-eye clickable");
    }
  };

  return (
    <div className="container">
      <main className="login">
        <form className="login-form">
          <div className="row">
            <div className="col"><img className="logoGrodi" src="/logo2.png" /></div>
          </div>
          <h3 className="login-title mb-4">Crear cuenta</h3>

          <div className="form-group mb-2 position-relative check-valid is-valid">
            <div className="input-group input-group-lg">
              <span className="input-group-text text-theme border-end-0"><i className="bi bi-envelope"></i></span>
              <div className="form-floating">
                <input
                  type="email"
                  placeholder="Email"
                  autoComplete="off"
                  name="email"
                  autoFocus
                  required
                  defaultValue={register.email}
                  onChange={handleChange}
                  className="form-control border-start-0" />
                <label>Email</label>
              </div>
            </div>
          </div>

          <div className="form-group mb-2 position-relative check-valid is-valid">
            <div className="input-group input-group-lg">
              <span className="input-group-text text-theme border-end-0"><i className="bi bi-person"></i></span>
              <div className="form-floating">
                <input
                  type="text"
                  placeholder="Introduce tu nombre"
                  autoComplete="off"
                  name="name"
                  required
                  defaultValue={register.name}
                  onChange={handleChange}
                  className="form-control border-start-0" />
                <label>Nombre</label>
              </div>
            </div>
          </div>

          <div className="form-group mb-2 position-relative check-valid is-valid">
            <div className="input-group input-group-lg">
              <span className="input-group-text text-theme border-end-0"><i className="bi bi-people"></i></span>
              <div className="form-floating">
                <input
                  type="text"
                  placeholder="Introduce tus apellidos"
                  autoComplete="off"
                  name="lastname"
                  required
                  defaultValue={register.lastname}
                  onChange={handleChange}
                  className="form-control border-start-0" />
                <label>Apellidos</label>
              </div>
            </div>
          </div>

          <div className="form-group mb-2 position-relative check-valid is-valid">
            <div className="input-group input-group-lg">
              <span className="input-group-text text-theme border-end-0"><i className="bi bi-phone"></i></span>
              <div className="form-floating">
                <input
                  type="text"
                  placeholder="Introduce tu telefono"
                  autoComplete="off"
                  name="phone"
                  required
                  defaultValue={register.phone}
                  onChange={handleChange}
                  className="form-control border-start-0"
                />
                <label>Teléfono</label>
              </div>
            </div>
          </div>

          <hr />

          <div className="form-group mb-2 position-relative check-valid">
            <div className="input-group input-group-lg">
              <span className="input-group-text text-theme border-end-0"><i className="bi bi-file-earmark-check"></i></span>
              <div className="form-floating">
                <select
                  className="form-select border-0 border-start-0"
                  name="profile"
                  value={register.profile}
                  onChange={handleChange}
                  title="profile" >
                  <option value="0">Público</option>
                  <option value="1">Privado</option>
                </select>
                <label>Visibilidad del perfil</label>
              </div>
            </div>
          </div>

          <hr />

          <div className="form-group mb-2 position-relative check-valid">
            <div className="input-group input-group-lg">
              <span className="input-group-text text-theme border-end-0"><i className="bi bi-key"></i></span>
              <div className="form-floating">
                <input
                  id="password1"
                  type="password"
                  placeholder="Introduce tu contraseña"
                  autoComplete="off"
                  name="password"
                  required
                  defaultValue={register.password}
                  onChange={handleChange}
                  className="form-control border-start-0 border-end-0"
                />
                <label htmlFor="password1">Contraseña</label>
              </div>
              <span className="input-group-text text-secondary  border-start-0" id="viewpassword1" onClick={viewPass}><i className="bi bi-eye clickable"></i></span>
            </div>
          </div>

          <div className="form-group mb-2 position-relative check-valid">
            <div className="input-group input-group-lg">
              {passMatch && (<span className="input-group-text text-theme border-end-0 text-success"><i className="bi bi-unlock"></i></span>)}
              {!passMatch && (<span className="input-group-text text-theme border-end-0 text-danger"><i className="bi bi-lock"></i></span>)}
              <div className="form-floating">
                <input
                  type="password"
                  placeholder="Re-introduzca contraseña"
                  defaultValue={register.password2}
                  name="password2"
                  required
                  className="form-control border-start-0 border-end-0"
                  onChange={handleChange}
                  id="password2" />
                <label htmlFor="password2">Confirme Contraseña</label>
              </div>
              <span className="input-group-text text-secondary  border-start-0" id="viewpassword2" onClick={viewRePass}><i className="bi bi-eye clickable"></i></span>
            </div>
          </div>
        
          <hr />

          <div className="form-check text-start">
            <input className="form-check-input" type="checkbox" name="privacy" value={register.privacy} id="privacy" onChange={handleChange} />
            <label className="form-check-label" htmlFor="privacy">
              Acepto la <a className="login-link" href="https://www.groditech.com/legal/es" target="_blank"><span className="log-link">política de privacidad</span></a>
            </label>
          </div>
          <div className="form-check text-start">
            <input className="form-check-input" type="checkbox" name="ageVerification" value={register.ageVerification} id="ageverification" onChange={handleChange} />
            <label className="form-check-label" htmlFor="ageverification">
              Declaro que tengo 18 años o más
            </label>
          </div>

          <div className="row">
            {serverError && 
              (<div className="text-danger fw-bold my-2">
                Se ha producido un error<br/>
              </div>)
            }

            {exists && 
              (<div className="text-danger fw-bold my-2">
                Ya existe una cuenta con este correo.
              </div>)
            }

            {showCheckMail && 
              (<div className="col text-success fw-bold fs-5 my-3">
                Se ha creado tu cuenta.<br />¡Ve a tu correo para confirmarlo!
              </div>)
            }
          </div>

          <div className="row mt-2">
            {!showCheckMail && 
              (<div className="col">
                <button className="btn btn-lg login-btn my-3 w-100" id="submitsignupgrodi" type="button" onClick={handleSubmit} disabled={disableBtn}>Registrar</button>
              </div>)
            }
            <div className="text-body">Ya tengo cuenta: <Link as={Link} to="/" className="login-link"><span>LOGIN</span></Link></div>
          </div>

        </form>
      </main>
    </div>
  );
};
